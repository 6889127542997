import React, { Component } from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import loadable from 'loadable-components';
import classNames from 'classnames';

import {
  PROFILE,
  DASHBOARD,
  STATEMENTS,
  MAKE_PAYMENT,
  APPLY_FOR_CREDIT,
  RESOURCES,
  SA_DASHBOARD,
  SA_GROWERS,
  SA_RESOURCES,
  SA_PROFILE,
  SR_DASHBOARD,
  SR_GROWERS,
  SR_SEED_ADVISOR,
  SR_RESOURCES,
  SR_COLLECTIONS,
  ADMIN_BULLETIN,
  ADMIN_RESOURCES,
  ADMIN_RESOURCES_EDIT,
  ADMIN_GROWER_NOTIFICATIONS,
  ADMIN_SETTINGS,
  ADMIN_STATEMENTS,
  ADMIN_BATCH_JOBS,
  ADMIN_BATCH_JOB_EDIT,
  ADMIN_STATEMENT_MARKETING_ADS,
  NOTIFICATIONS,
  SA_NOTIFICATIONS,
  CSA_NOTIFICATIONS,
  SR_NOTIFICATIONS,
  CSA_DASHBOARD,
  SR_PROFILE,
  CSA_ALL_GROWER,
  CSA_ALL_SA,
  CSA_PROFILE,
  CSA_RESOURCES,
  CSA_SEARCH,
  SA_COLLECTIONS,
  CSA_COLLECTIONS,
  ADMIN_STATEMENT_MARKETING_ADS_EDIT,
  ADMIN_PAYMENT_SYNC,
  SA_SEED_CARE,
} from '../constants/RouteConstants';

const Profile = loadable(() => import('../grower/profile'));
const Dashboard = loadable(() => import('../grower/dashboard'));
const Statements = loadable(() => import('../grower/statements'));
const MakePayment = loadable(() => import('../grower/make-a-payment'));
const ApplyForCredit = loadable(() => import('../grower/apply-for-credit'));
const Resources = loadable(() => import('../grower/resources'));
const Notifications = loadable(() => import('../grower/notifications'));
const SANotifications = loadable(() => import('../seed-advisor/notifications'));
const SRNotifications = loadable(() =>
  import('../sales-representative/notifications')
);
const SRCollections = loadable(() =>
  import('../sales-representative/collections')
);

const SeedAdviserDashboard = loadable(() =>
  import('../seed-advisor/dashboard')
);
const SeedAdviserGrowers = loadable(() => import('../seed-advisor/growers'));
const SeedAdviserResources = loadable(() =>
  import('../seed-advisor/resources')
);
const SeedAdviserProfile = loadable(() => import('../seed-advisor/profile'));

const SalesRepresentativeDashboard = loadable(() =>
  import('../sales-representative/dashboard')
);
const SalesRepresentativeGrowers = loadable(() =>
  import('../sales-representative/growers')
);

const SalesRepresentativeSeedAdvisor = loadable(() =>
  import('../sales-representative/seed-advisers')
);

const SalesRepresentativeProfile = loadable(() =>
  import('../sales-representative/profile')
);
const SalesRepresentativeResources = loadable(() =>
  import('../sales-representative/resources')
);

const AdminBulletin = loadable(() => import('../admin/bulletin'));
const AdminResources = loadable(() => import('../admin/resources/list'));
const AdminResourcesEdit = loadable(() => import('../admin/resources/edit'));
const AdminNotifications = loadable(() => import('../admin/notifications'));
const AdminSettings = loadable(() => import('../admin/settings'));
const AdminBatchJobs = loadable(() => import('../admin/batch-jobs/list'));
const AdminBatchJobEdit = loadable(() => import('../admin/batch-jobs/edit'));
const AdminStatementMarketingAds = loadable(() =>
  import('../admin/statement-marketing-ads/list')
);
const AdminStatementMarketingAdsEdit = loadable(() =>
  import('../admin/statement-marketing-ads/edit')
);
const AdminPaymentSync = loadable(() => import('../admin/payment-sync'));
const AdminStatements = loadable(() => import('../admin/statements'));
const CsaDashboard = loadable(() => import('../customer-support/dashboard'));
const CsaAllGrowers = loadable(() => import('../customer-support/all-growers'));
const CsaAllSA = loadable(() =>
  import('../customer-support/all-seed-advisors')
);
const CSAProfile = loadable(() => import('../customer-support/profile'));
const CSANotifications = loadable(() =>
  import('../customer-support/notifications')
);
const CSAResources = loadable(() => import('../customer-support/resources'));
const CSASearch = loadable(() => import('../customer-support/search'));
const SACollections = loadable(() => import('../seed-advisor/collections'));
const CSACollections = loadable(() =>
  import('../customer-support/collections')
);
const SASEEDCARE = loadable(() => import('../seed-advisor/seedcare'));
class ContentBody extends Component {
  render() {
    const { Content } = Layout;
    const { isSiderCollapsed } = this.props;
    return (
      <Content
        className={classNames(
          'main-body',
          { 'margin-menu-hidden': isSiderCollapsed },
          { 'margin-menu-expanded': !isSiderCollapsed }
        )}
      >
        <Switch>
          <Route exact path={PROFILE} component={Profile} />
          <Route exact path={DASHBOARD} component={Dashboard} />
          <Route exact path={STATEMENTS} component={Statements} />
          <Route exact path={MAKE_PAYMENT} component={MakePayment} />
          <Route exact path={APPLY_FOR_CREDIT} component={ApplyForCredit} />
          <Route exact path={NOTIFICATIONS} component={Notifications} />
          <Route exact path={SA_NOTIFICATIONS} component={SANotifications} />
          <Route exact path={RESOURCES} component={Resources} />
          <Route exact path={SA_DASHBOARD} component={SeedAdviserDashboard} />
          <Route exact path={SA_GROWERS} component={SeedAdviserGrowers} />
          <Route exact path={SA_RESOURCES} component={SeedAdviserResources} />
          <Route exact path={SA_PROFILE} component={SeedAdviserProfile} />
          <Route exact path={SA_COLLECTIONS} component={SACollections} />
          <Route exact path={SA_SEED_CARE} component={SASEEDCARE} />
          <Route
            exact
            path={SR_DASHBOARD}
            component={SalesRepresentativeDashboard}
          />
          <Route
            exact
            path={SR_GROWERS}
            component={SalesRepresentativeGrowers}
          />
          <Route
            exact
            path={SR_SEED_ADVISOR}
            component={SalesRepresentativeSeedAdvisor}
          />
          <Route
            exact
            path={SR_PROFILE}
            component={SalesRepresentativeProfile}
          />
          <Route exact path={SR_NOTIFICATIONS} component={SRNotifications} />
          <Route exact path={SR_COLLECTIONS} component={SRCollections} />
          <Route
            exact
            path={SR_RESOURCES}
            component={SalesRepresentativeResources}
          />
          <Route exact path={ADMIN_BULLETIN} component={AdminBulletin} />
          <Route exact path={ADMIN_RESOURCES} component={AdminResources} />
          <Route
            exact
            path={ADMIN_RESOURCES_EDIT}
            component={AdminResourcesEdit}
          />
          <Route
            exact
            path={ADMIN_GROWER_NOTIFICATIONS}
            component={AdminNotifications}
          />
          <Route exact path={ADMIN_SETTINGS} component={AdminSettings} />
          <Route exact path={ADMIN_BATCH_JOBS} component={AdminBatchJobs} />
          <Route
            exact
            path={ADMIN_BATCH_JOB_EDIT}
            component={AdminBatchJobEdit}
          />
          <Route exact path={ADMIN_STATEMENTS} component={AdminStatements} />
          <Route
            exact
            path={ADMIN_STATEMENT_MARKETING_ADS}
            component={AdminStatementMarketingAds}
          />
          <Route
            exact
            path={ADMIN_STATEMENT_MARKETING_ADS_EDIT}
            component={AdminStatementMarketingAdsEdit}
          />
          <Route exact path={ADMIN_PAYMENT_SYNC} component={AdminPaymentSync} />
          <Route exact path={CSA_DASHBOARD} component={CsaDashboard} />
          <Route exact path={CSA_PROFILE} component={CSAProfile} />
          <Route
            exact
            path={CSA_ALL_GROWER}
            render={() => (
              <CsaAllGrowers
                isSuperadmin={this.props.user && this.props.user.is_superadmin}
              />
            )}
          />
          <Route
            exact
            path={CSA_ALL_SA}
            render={() => (
              <CsaAllSA
                isSuperadmin={this.props.user && this.props.user.is_superadmin}
              />
            )}
          />
          <Route exact path={CSA_NOTIFICATIONS} component={CSANotifications} />
          <Route exact path={CSA_RESOURCES} component={CSAResources} />
          <Route exact path={CSA_SEARCH} component={CSASearch} />
          <Route exact path={CSA_COLLECTIONS} component={CSACollections} />
        </Switch>
      </Content>
    );
  }
}

export default ContentBody;
